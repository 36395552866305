@keyframes tabsSlideIn {
  0% {
    left: -100%;
  }
  100% {
    left: 0px;
  }
}

@keyframes tabsSlideOut {
  0% {
    left: 0;
  }
  100% {
    left: 100%;
  }
}
